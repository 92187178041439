<template>
  <div class="formTest">
      <Form/>
  </div>
</template>

<script>
// @ is an alias to /src

import Form from '@/components/Form.vue'

export default {
  name: 'Home',
  components: {
    Form
  }
}
</script>

<style lang="scss">
  .formTest{
      max-width: 60em;
  padding: 2em;
  margin: 0 auto;
  }
</style>
