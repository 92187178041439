<template>
<div>  
  <editor
    ref="editor"
    :config="config"
    :initialized="onInitialized"
    @ready="onReady"
    @change="onChange"
    :init-data="initData"
  />
  <div v-if="![]">
    {{ JSON.stringify(this.dataOutput) }}
  </div>
</div>
</template>

<script>
import Header from "@editorjs/header";
import CodeTool from "@editorjs/code";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Checklist from "@editorjs/checklist";
import Marker from "@editorjs/marker";
import Quote from "@editorjs/quote";
import InlineCode from "@editorjs/inline-code";
import ImageTool from "@editorjs/image";
import NestedList from "@editorjs/nested-list";
import Underline from "@editorjs/underline";
import Delimiter from "@editorjs/delimiter";
import Paragraph from "editorjs-paragraph-with-alignment";
import Carousel from "@vietlongn/editorjs-carousel";
import Undo from "editorjs-undo";
import DragDrop from "editorjs-drag-drop";

export default {
  props: ["initData"],
  data() {
    return {
      dataOutput: {},
      config: {
        tools: {
          header: {
            class: Header,
            config: {
              placeholder: "Hier möchte eine Überschrift hin",
              levels: [2, 3, 4],
              defaultLevel: 2,
            },
          },
          list: {
            class: NestedList,
            inlineToolbar: true,
          },
          code: {
            class: CodeTool,
          },
          paragraph: {
            class: Paragraph,
          },
          embed: {
            class: Embed,
            config: {
              services: {
                youtube: true,
                vimeo: true,
                "twitch-channel": true,
                "twitch-video": true,
                instagram: true,
                codepen: true,
                gfycat: true,
              },
            },
            inlineToolbar: true,
          },
          table: {
            class: Table,
            inlineToolbar: true,
            config: {
              rows: 2,
              cols: 2,
            },
          },
          checklist: {
            class: Checklist,
          },
          // INLINE
          marker: {
            class: Marker,
            shortcut: "CMD+SHIFT+M",
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: "CMD+SHIFT+O",
            config: {
              quotePlaceholder: "Hier entsteht ein Zitat",
              captionPlaceholder: "Autor",
            },
          },
          inlinecode: {
            class: InlineCode,
            shortcut: "CMD+SHIFT+M",
          },
          image: ImageTool,
          underline: Underline,
          delimiter: Delimiter,
          carousel: Carousel,
        },
        onChange: this.onChange,
        data: this.initData,
      },
      
    };
  },
  methods: {
    onInitialized(editor) {
      console.log(editor);
    },
    onReady(editor) {
      new Undo({ editor });
      new DragDrop(editor);
    },
    async onChange(args){
      let resp = await args.saver.save();
      this.dataOutput = resp;
      this.$emit('change', resp);
    }
  },
};
</script>


<style lang="css" >
#vue-editor-js {
  width: 100%;
  /* border: 1px solid black; */
  box-sizing: border-box;
}
.codex-editor__redactor {
  padding-bottom: 2em !important;
}
.ce-block--focused {
  background: linear-gradient(
    45deg,
    rgb(197, 255, 200) 0%,
    rgb(255, 255, 255) 30%,
    rgb(255, 255, 255) 70%,
    rgb(197, 255, 200) 100%
  );
}
</style>