import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueFormulate from '@braid/vue-formulate'
import Editor from 'vue-editor-js/src'
import { de, en } from '@braid/vue-formulate-i18n'

Vue.config.productionTip = false

import editorjs from "@/components/Editor.vue";
Vue.component('editorjs', editorjs)

Vue.use(VueFormulate, {
  plugins: [de, en],
  slotProps: {
    editorjs: ['initData'],
  }
})
Vue.use(Editor)

new Vue({
  router,
  store,
  i18n,
  created() {
    const getHTMLTag = document.documentElement;
    getHTMLTag.setAttribute("lang", i18n.locale);
  },
  render: h => h(App)
}).$mount('#app')
