<template>
  <FormulateForm v-model="formOutput" :schema="schema" class="formulate-form">
    <div class="output">
      {{ JSON.stringify(formOutput, this.getCircularReplacer()) }}
    </div>
  </FormulateForm>
</template>

<script>
export default {
  data() {
    return {
      maxShortBodyLength: 120,
      formOutput: {
        media: [
          {
            enabled: true,
          },
        ],
        body: [{ enabled: false }],
        metadata: [
          {
            "team-enabled": false,
            "funding-enabled": false,
            "teachers-enabled": true,
          },
        ],
      }, // Form return Obj
      projectTypes: {
        // would be loaded from directus probably! Key would be UUID
        kb: { en: "Coursework Bachelor", de: "Kursprojekt Bachelor" },
        km: { en: "Corsework Master", de: "Kursprojekt Master" },
        ba: { de: "Bachelor Arbeit" },
        ma: { de: "Master Arbeit" },
        ext: { de: "Externes Projekt" },
        other: { de: "Sonstiges" },
      },
      institutions: {
        "Intermedia Design": {
          "id-default": { de: "Sonstiges" },
          mr: { de: "Medienräume" },
          av: { de: "Audiovisuelle Medien" },
          game: { de: "Spieleentwicklung" },
        },
        Kommunikationsdesign: {
          "kd-default": { de: "Sonstiges" },
          ledw: { de: "Crossmedia" },
          typo: { de: "Typografie" },
        },
        Sonstiges: {
          default: { de: "Sonstiges" },
        },
      },
      defaultLongBody: {
        time: 1617644269109,
        blocks: [
          {
            type: "header",
            data: { text: "Meine ausführliche Beschreibung...", level: 2 },
          },
          {
            type: "paragraph",
            data: {
              text:
                "Übrigens: Der erste Textblock (Also im Moment der hier :D) wird automatisch zur Kurzbeschreibung!",
              alignment: "left",
            },
          },
          {
            type: "checklist",
            data: {
              items: [
                { text: "Kann Texte enthalten", checked: true },
                {
                  text:
                    "Und ÜberschriftenUnd Listen (Sogar mit mehreren leveln)",
                  checked: true,
                },
                { text: "Und code (falls man es mal braucht)", checked: true },
                { text: "Und tabellen (Spannend!)", checked: true },
                {
                  text: 'Checklisten (Wie diese)"Zitate" (- Mark Twain)',
                  checked: true,
                },
                {
                  text: "BilderUnterteilungen (sponsored by Marie Kondo)",
                  checked: true,
                },
                { text: "Und Bilder-Carousels", checked: true },
                {
                  text:
                    "Und inline tools gibt's auch! (Einfach den text markieren)",
                  checked: true,
                },
              ],
            },
          },
          {
            type: "paragraph",
            data: {
              text:
                '<b><i>Neue Blöcke</i></b> fügt man mit <code class="inline-code">Strg+Enter</code> hinzu (<code class="inline-code">Shift+Enter</code> wenn der Umbruch im Block passieren soll)<br>Und mit dem kleinen<mark class="cdx-marker"> + </mark>an der linken Seite oder mit <code class="inline-code">Tab</code> kann man den Block-Typen aussuchen!<br>Wenn man in einen Text-Block eine <u class="cdx-underline">youtube, vimeo, gfycat, codepen, ...</u> URL einfügt wird der passende inhalt eingebunden!',
              alignment: "left",
            },
          },
          {
            type: "embed",
            data: {
              service: "gfycat",
              source: "https://gfycat.com/secondgiftedbantamrooster",
              embed: "https://gfycat.com/ifr/secondgiftedbantamrooster",
              width: 580,
              height: 436,
              caption: "Wow! Medien!",
            },
          },
        ],
        version: "2.19.3",
      },
    };
  },
  computed: {
    imagesForHero: function () {
      let returnObj = {};
      if (
        this.formOutput.media &&
        this.formOutput.media[0].images &&
        this.formOutput.media[0].images.files.length > 0
      ) {
        let filesData = this.formOutput.media[0].images.files;
        console.dir(filesData);
        for (var key in filesData) {
          // grab file name and chuck into list. TODO: IDK how to resolve this. maybe do something better with it later.
          returnObj[key] = filesData[key].name;
        }
      }
      return returnObj;
    },
    i18nInstitutions: function () {
      return this.getI18n(this.institutions);
    },
    i18nActiveInstitutions: function () {
      let returnInstIds = {}
      let returnInsts = {};
      for (const key in this.formOutput.metadata[0].teachers){
        const instId = this.formOutput.metadata[0].teachers[key].institution;
        returnInstIds[instId] = instId;
      }
      for (const key in this.formOutput.metadata[0].students){
        const instId = this.formOutput.metadata[0].students[key].institution;
        returnInstIds[instId] = instId;
      }
      for(const instParent in this.i18nInstitutions){
        let instReturn = {};
        const currentInstGroup = this.i18nInstitutions[instParent];
        console.log(currentInstGroup)
        for(const instId in returnInstIds){
          if(Object.prototype.hasOwnProperty.call(currentInstGroup, instId)){
            instReturn[instId] = currentInstGroup[instId];
          }
        }
        if(Object.keys(instReturn).length > 0){
          returnInsts[instParent] = instReturn;
        }
      }
      return returnInsts;
    },
    activeInsitutionsSchema: function () {
      let returnOptions = []
      const instSchema = {
          type: "checkbox",
          name: "home-institution",
          
          validation: "required",
        }

        let instSets
        if(this.formOutput.homeinstitution && this.formOutput.homeinstitution[0]["advanced-institution-selection"] == true){
          instSets = this.i18nInstitutions;
        } else {
          instSets = this.i18nActiveInstitutions;
        }
        
        for(const instSetName in instSets){
          let newSchema = {...instSchema}
          newSchema.label = instSetName
          newSchema.options = instSets[instSetName]
          returnOptions.push(newSchema)
        }


      return returnOptions
    },
    i18nProjectTypes: function () {
      return this.getI18n(this.projectTypes);
    },
    schema: function () {
      return [
        {
          component: "h2",
          children: this.$t("ingestform.header"),
        },
        {
          type: "group",
          class: "capsheading",
          name: "quickinfo",
          label: this.$t("ingestform.quickinfo.label"),
          validation: "required",
          children: [
            {
              component: "fieldset",
              class: "double-row",
              children: [
                {
                  name: "title",
                  label: this.$t("ingestform.quickinfo.title.label"),
                  validation: "required",
                  class: "fullwidth",
                  placeholder: this.$t(
                    "ingestform.quickinfo.title.placeholder"
                  ),
                },
                {
                  type: "select",
                  name: "type",
                  label: this.$t("ingestform.quickinfo.type.label"),
                  validation: "required",
                  placeholder: this.$t("ingestform.quickinfo.type.placeholder"),
                  options: this.i18nProjectTypes,
                },
                {
                  type: "date",
                  name: "publishing-date",
                  validation: "required",
                  label: this.$t("ingestform.quickinfo.publishing-date.label"),
                },
              ],
            },
          ],
        },
        {
          component: "hr",
        },
        {
          type: "group",
          class: "capsheading",
          name: "media",
          label: this.$t("ingestform.media.label"),
          children: [
            {
              type: "checkbox",
              name: "enabled",
              label: this.$t("ingestform.media.enable.label"),
            },
            {
              component: "fieldset",
              class: this.formOutput.media[0].enabled
                ? "group-visible"
                : "group-hidden",
              validation: this.formOutput.media[0].enabled ? "required" : null,
              children: [
                {
                  type: "image",
                  name: "images",
                  label: this.$t("ingestform.media.images.label"),
                  multiple: true,
                  validation: this.formOutput.media[0].enabled
                    ? "required"
                    : null,
                },
                {
                  type: "select",
                  name: "hero",
                  label: this.$t("ingestform.media.hero.label"),
                  class:
                    (Object.keys(this.imagesForHero).length > 1
                      ? "visible"
                      : "hidden") + " dropdown",
                  disabled: Object.keys(this.imagesForHero).length < 2,
                  options: this.imagesForHero,
                },
                {
                  type: "file",
                  name: "videos",
                  label: this.$t("ingestform.media.videos.label"),
                  validation: "mime:video",
                },
              ],
            },
          ],
        },
        {
          component: "hr",
        },
        {
          type: "group",
          class: "capsheading",
          name: "body",
          label: this.$t("ingestform.body.label"),
          children: [
            {
              type: "checkbox",
              name: "enabled",
              label: this.$t("ingestform.body.enable.label"),
            },
            {
              component: "textarea",
              name: "body-short",
              class: !this.formOutput.body[0].enabled
                ? "group-visible"
                : "group-hidden",
              placeholder: this.$t("ingestform.body.short.placeholder", {
                maxLength: this.maxShortBodyLength,
              }),
              validation:
                (!this.formOutput.body[0].enabled ? "required|" : "") +
                "max:" +
                this.maxShortBodyLength +
                ",length",
              "error-behavior": "live",
            },
            {
              component: "fieldset",
              name: "body-long-container",
              class: this.formOutput.body[0].enabled
                ? "group-visible"
                : "group-hidden",
              validation: this.formOutput.body[0].enabled ? "required" : null,
              children: [
                {
                  name: "body-long",
                  component: "editorjs",
                  initData: this.defaultLongBody,
                },
              ],
            },
          ],
        },
        {
          component: "hr",
        },
        {
          type: "group",
          class: "capsheading",
          name: "metadata",
          label: this.$t("ingestform.metadata.label"),
          children: [
            {
              type: "checkbox",
              name: "teachers-enabled",
              default: true,
              label: this.$t("ingestform.metadata.teachers-enabled.label"),
            },
            {
              type: "checkbox",
              name: "group-enabled",
              label: this.$t("ingestform.metadata.group-enabled.label"),
            },
            {
              type: "checkbox",
              name: "partners-enabled",
              label: this.$t("ingestform.metadata.team-enabled.label"),
            },
            {
              type: "checkbox",
              name: "funding-enabled",
              label: this.$t("ingestform.metadata.funding-enabled.label"),
            },
            {
              component: "fieldset",
              name: "team",
              // label: this.$t("ingestform.metadata.team.data.label"),
              children: [
                {
                  name: "teachers",
                  label: this.$t("ingestform.metadata.team.teachers.label"),
                  type: "group",
                  repeatable: true,
                  class:
                    (this.formOutput.metadata[0]["teachers-enabled"]
                      ? "group-visible"
                      : "group-hidden") + " double-row-group",
                  validation: this.formOutput.metadata[0]["teachers-enabled"]
                    ? "required"
                    : null,
                  children: [
                    {
                      type: "text",
                      name: "name",
                      label: this.$t(
                        "ingestform.metadata.team.students.name.label"
                      ),
                      validation: "required",
                      placeholder: this.$t(
                        "ingestform.metadata.team.teachers.name.placeholder"
                      ),
                    },
                    {
                      type: "select",
                      name: "institution",
                      label: this.$t(
                        "ingestform.metadata.team.teachers.institution.label"
                      ),
                      validation: "required",
                      "option-groups": this.i18nInstitutions,
                    },
                    {
                      type: "email",
                      name: "account",
                      label: this.$t(
                        "ingestform.metadata.team.teachers.email.label"
                      ),
                      placeholder: this.$t(
                        "ingestform.metadata.team.teachers.email.placeholder"
                      ),
                    },
                  ],
                },
                {
                  name: "students",
                  label: this.$t("ingestform.metadata.team.students.label"),
                  type: "group",
                  repeatable: this.formOutput.metadata[0]["group-enabled"],
                  class: "double-row-group",
                  children: [
                    {
                      type: "text",
                      name: "name",
                      label: this.$t(
                        "ingestform.metadata.team.students.name.label"
                      ),
                      validation: this.formOutput.metadata[0]["group-enabled"]
                        ? "required"
                        : null,
                      placeholder: this.$t(
                        "ingestform.metadata.team.students.name.placeholder"
                      ),
                    },
                    {
                      type: "select",
                      name: "institution",
                      label: this.$t(
                        "ingestform.metadata.team.students.institution.label"
                      ),
                      validation: this.formOutput.metadata[0]["group-enabled"]
                        ? "required"
                        : null,
                      "option-groups": this.i18nInstitutions,
                    },
                    {
                      type: "email",
                      name: "account",
                      label: this.$t(
                        "ingestform.metadata.team.students.email.label"
                      ),
                      placeholder: this.$t(
                        "ingestform.metadata.team.students.email.placeholder"
                      ),
                    },
                  ],
                },
                {
                  // TODO: try to make this more number-looking
                  type: "number",
                  name: "anonymous-students",
                  label: this.$t("ingestform.metadata.team.number-students"),
                  class:
                    (this.formOutput.metadata[0]["group-enabled"]
                      ? "group-visible"
                      : "group-hidden") + " double-row-group",
                },
                {
                  name: "partners",
                  label: this.$t("ingestform.metadata.team.partners.label"),
                  type: "group",
                  repeatable: true,
                  class:
                    (this.formOutput.metadata[0]["partners-enabled"]
                      ? "group-visible"
                      : "group-hidden") + " double-row-group",
                  validation: this.formOutput.metadata[0]["partners-enabled"]
                    ? "required"
                    : null,
                  children: [
                    {
                      type: "text",
                      name: "name",
                      label: this.$t(
                        "ingestform.metadata.team.partners.name.label"
                      ),
                      validation: this.formOutput.metadata[0][
                        "partners-enabled"
                      ]
                        ? "required"
                        : null,
                      placeholder: this.$t(
                        "ingestform.metadata.team.partners.name.placeholder"
                      ),
                    },
                    {
                      type: "text",
                      name: "function",
                      label: this.$t(
                        "ingestform.metadata.team.partners.function.label"
                      ),
                      validation: this.formOutput.metadata[0][
                        "partners-enabled"
                      ]
                        ? "required"
                        : null,
                      placeholder: this.$t(
                        "ingestform.metadata.team.partners.function.placeholder"
                      ),
                    },
                  ],
                },
                {
                  name: "funding",
                  label: this.$t("ingestform.metadata.team.funding.label"),
                  type: "group",
                  repeatable: true,
                  class:
                    (this.formOutput.metadata[0]["funding-enabled"]
                      ? "group-visible"
                      : "group-hidden") + " double-row-group",
                  validation: this.formOutput.metadata[0]["funding-enabled"]
                    ? "required"
                    : null,
                  children: [
                    {
                      type: "text",
                      name: "name",
                      label: this.$t(
                        "ingestform.metadata.team.funding.name.label"
                      ),
                      validation: this.formOutput.metadata[0]["funding-enabled"]
                        ? "required"
                        : null,
                      placeholder: this.$t(
                        "ingestform.metadata.team.funding.name.placeholder"
                      ),
                    },
                    {
                      type: "number",
                      name: "amount",
                      label: this.$t(
                        "ingestform.metadata.team.funding.amount.label"
                      ),
                      placeholder: "123.45",
                      validation: this.formOutput.metadata[0]["funding-enabled"]
                        ? "required"
                        : null,
                    },
                  ],
                },
              ],
            },
          ],
        },
                {
          component: "hr",
        },
        {
                    type: "group",
          class: "capsheading",
          name: "homeinstitution",
          label: this.$t("ingestform.home-institution.label"),
          children: [
                        {
              component: "fieldset",
              name: "team",
              children: this.activeInsitutionsSchema,
              },
              {
                type: "checkbox",
                name: "advanced-institution-selection",
                label: "Weitere Anzeigen"
              }
          ],
          
        },
        {
          type: "submit",
        },
      ];
    },
  },
  methods: {
    getCircularReplacer() {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    },
    getI18n(obj) {
      let returnObj = {};
      for (var key in obj) {
        const objPart = obj[key];
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          // exclude inherited
          const objectLength = Object.keys(objPart).length;
          if (objectLength > 0) {
            const objChild = objPart[Object.keys(objPart)[0]];
            const objChildType = typeof objChild;
            console.log({ objChild, objectLength, objChildType });
            if (typeof objChild === "object" && objChild !== null) {
              returnObj[key] = this.getI18n(objPart);
            } else {
              // check if valid structure
              // if selected locale exists use that, if not use fallback, if that doesn't exist, use any first object child.
              returnObj[key] =
                objPart[this.$i18n.locale] ||
                objPart[this.$i18n.fallbackLocale] ||
                objPart[Object.keys(objPart)[0]];
            }
          } else {
            // if object is malformed display key.
            returnObj[key] = key;
          }
        }
      }
      return returnObj;
    },
  },
};
</script>


<style lang="scss">
.formulate-form{

fieldset,
.group-visible,
.group-hidden {
  position: relative;
  width: 100%;
  border-radius: 0.3em;
}
.double-row {
  display: flex;
  flex-wrap: wrap;
}
.double-row > .formulate-input {
  width: 50%;
  min-width: 15em;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 1em;
  display: inline-block;
}
.double-row > .fullwidth {
  width: 100%;
}
.double-row-group .formulate-input-group-repeatable {
  display: flex;
  flex-wrap: wrap;
  .formulate-input {
    min-width: 10em;
    width: 32%;
    flex-shrink: 1;
    flex-grow: 1;
    margin-bottom: 0;
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
}
.formulate-input .formulate-input-element {
  width: auto;
  max-width: 60em;
}
textarea {
  border: 1px solid #a0a0a0;
  min-height: 12em;
  &.group-hidden {
    min-height: 3em;
    opacity: 50%;
  }
}
hr {
  margin: 2em 0;
  border-top: 1px solid rgba(165, 0, 52, 1);
}
.capsheading > div > label {
  text-transform: uppercase;
}
  .hidden {
    display: none;
    visibility: hidden;
  }
.group-hidden {
  pointer-events: none;
  height: 3em !important;
  overflow: hidden;
  position: relative;
  border-bottom: none;
  border-radius: 0.3em 0.3em 0 0;



  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3em;
    z-index: 1000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}
}
</style>